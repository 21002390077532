import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { Component, NgZone } from '@angular/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { loadUser } from "./state/user/user.actions";
import { Store } from "@ngrx/store";
import { available_rentalActions } from "./state/available_rental/available_rental.actions";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent {
    constructor(
        private zone: NgZone,
        private router: Router,
        private authService: AuthService,
        private store: Store
    ) {
        this.setupListener();
        this.initStore();
    }

    setupListener() {
        App.addListener('appUrlOpen', async (data: URLOpenListenerEvent) => {
            console.log('app opened with URL: ', data);

            const openUrl = data.url;
            // const slug = openUrl.split('login').pop();
            // const navigateUrl = `/groups${slug}`;
            // console.log('use url: ', navigateUrl);
            const access = openUrl.split('#access_token=').pop().split('&')[0];
            console.log('access: ', access);
            const refresh = openUrl.split('&refresh_token=').pop().split('&')[0];
            console.log('refresh: ', refresh);

            await this.authService.setSession(access, refresh);

            this.zone.run(() => {
                this.router.navigateByUrl('/events', { replaceUrl: true });

                // this.location.replaceState(navigateUrl);
                // window.location.reload();
            });
        });
    }

    private initStore() {
        this.store.dispatch(loadUser());
        this.store.dispatch(available_rentalActions.loadAvailableRentals());
    }
}
