import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { EventsResolver } from "./pages/events/events.resolver";
import { EventsDetailsResolver } from "./pages/events-details/events-details.resolver";

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/events/events.module').then((m) => m.EventsPageModule),
    resolve: {
      events: EventsResolver
    }
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then((m) => m.RegisterPageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),

  },
  {
    path: 'events/:eventid',
    loadChildren: () => import('./pages/events-details/events-details.module').then(m => m.EventsDetailsPageModule),
    resolve: {
      events: EventsDetailsResolver
    }
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
