import { Injectable } from '@angular/core';
import { User } from "../state/user/user.model";
import { SupabaseService } from "./supabase.service";

@Injectable({ providedIn: 'root' })
export class UserService {

  constructor(private supabase: SupabaseService) {
  }

  async getUser(): Promise<User> {
    return this.supabase.client
      .from('users')
      .select('*')
      .match({ id: (await this.supabase.client.auth.getUser()).data.user.id })
      .single()
      .then((result) => {
        return result.data;
      });
  }

  async saveUser(user: User) {
    const saveUser = {
      id: (await this.supabase.client.auth.getUser()).data.user.id,
      name: user.name,
      surname: user.surname,
      nickname: user.nickname,
      phone: user.phone,
    };

    return this.supabase.client.from('users').upsert(saveUser).select().single();
  }
}
