import { createReducer, on } from '@ngrx/store';
import { User } from "./user.model";
import { loadUser, loadUserFailure, loadUserSuccess, saveUser, saveUserFailure, saveUserSuccess } from "./user.actions";

export interface UserState {
  user: User;
  error: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed' | string;
}

export const initialState: UserState = {
  user: undefined,
  error: null,
  status: 'pending',
};

export const userReducer = createReducer(
  // Supply the initial state
  initialState,
  on(loadUser, (state) => ({ ...state, status: 'loading' })),
  on(saveUser, (state, { user }) => ({
    ...state,
    user: user,
    error: null,
    status: 'success',
  })),
  on(saveUserSuccess, (state, { user }) => ({
    ...state,
    user: user,
    error: null,
    status: 'success',
  })),
  on(saveUserFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: 'error',
  })),
  on(loadUserSuccess, (state, { user }) => ({
    ...state,
    user: user,
    error: null,
    status: 'success',
  })),
  on(loadUserFailure, (state, { error }) => ({
    ...state,
    error: error,
    status: 'error',
  }))
);
