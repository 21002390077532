import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastController } from "@ionic/angular/standalone";
import { EventService } from "../../services/event.service";
import { eventActions } from "./event.actions";
import { EventRegistrationStatusEnum } from "./event.model";

@Injectable()
export class EventEffects {
    constructor(
        private actions$: Actions,
        private eventService: EventService,
        private toastController: ToastController
    ) {
    }

    loadEvents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(eventActions.loadEvents),
            switchMap(() =>
                from(this.eventService.loadEvents()).pipe(
                    map((events) => {
                        events.map((event) => {
                            event.is_registered = event.registrations.some((registration) => registration.event_id === event.id);
                            event.registered_user_count = event.registered_user_count?.[0]?.count;
                            event.approved_user_count = event.approved_user_count?.[0]?.count;
                        })
                        return eventActions.loadEventsSuccess({ events })
                    }),
                    catchError((error) => of(eventActions.loadEventsFailure({ error })))
                )
            )
        )
    );

    saveEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(eventActions.saveEvent),
            switchMap(({ event }) =>
                of(event).pipe(
                    map((event) => eventActions.saveEventSuccess({ event })),
                    catchError((error) => of(eventActions.saveEventFailure({ error })))
                )
            )
        )
    );

    deleteEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(eventActions.deleteEvent),
            switchMap(({ eventId }) =>
                of(eventId).pipe(
                    map((eventId) => eventActions.deleteEventSuccess({ eventId })),
                    catchError((error) => of(eventActions.deleteEventFailure({ error })))
                )
            )
        )
    );

    removeFromEvent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(eventActions.removeFromEvent),
            switchMap(({ eventId, registrationId }) =>
                from(this.eventService.deleteEventRegistrations(registrationId)).pipe(
                    map((registrations) => {
                        this.toastController
                            .create({
                                message: 'Registration removed successfully.',
                                duration: 2000,
                            })
                            .then((toast) => toast.present());
                        return eventActions.removeFromEventSuccess({ eventId, registration: registrations[0] })
                    }
                    ),
                    catchError((error) => {
                        this.toastController
                            .create({
                                message: `Error: ${error}`,
                                duration: 2000,
                            })
                            .then((toast) => toast.present());
                        return of(eventActions.removeFromEventFailure({ error }))
                    })
                )
            ),
        )
    );

    approveRegistration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(eventActions.approveRegistration),
            switchMap(({ eventId, registrationId }) =>
                from(this.eventService.updateEventRegistrations(registrationId, { status: EventRegistrationStatusEnum.APPROVED })).pipe(
                    map((registrations) => {
                        this.toastController
                            .create({
                                message: 'Registration approved successfully.',
                                duration: 2000,
                            })
                            .then((toast) => toast.present());
                        return eventActions.approveRegistrationSuccess({ eventId, registration: registrations[0] })
                    }
                    ),
                    catchError((error) => {
                        this.toastController
                            .create({
                                message: `Error: ${error}`,
                                duration: 2000,
                            })
                            .then((toast) => toast.present());
                        return of(eventActions.approveRegistrationFailure({ error }))
                    })
                )
            ),
        )
    );

    rejectRegistration$ = createEffect(() =>
        this.actions$.pipe(
            ofType(eventActions.rejectRegistration),
            switchMap(({ eventId, registrationId, rejectReason }) =>
                from(this.eventService.updateEventRegistrations(registrationId, { status: EventRegistrationStatusEnum.REJECTED, rejected_reason: rejectReason })).pipe(
                    map((registrations) => {
                        this.toastController
                            .create({
                                message: 'Registration rejected successfully.',
                                duration: 2000,
                            })
                            .then((toast) => toast.present());
                        return eventActions.rejectRegistrationSuccess({ eventId, registration: registrations[0] })
                    }
                    ),
                    catchError((error) => {
                        this.toastController
                            .create({
                                message: `Error: ${error}`,
                                duration: 2000,
                            })
                            .then((toast) => toast.present());
                        return of(eventActions.rejectRegistrationFailure({ error }))
                    })
                )
            ),
        )
    );

}
