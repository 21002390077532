import { inject, Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { areEventsLoaded } from "../../state/event/event.selectors";
import { eventActions } from "../../state/event/event.actions";

@Injectable({
  providedIn: 'root'
})
export class EventsDetailsResolver implements Resolve<boolean> {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(areEventsLoaded).pipe(
      tap(loaded => {
        if (!loaded) {
          this.store.dispatch(eventActions.loadEvents());
        }
      }),
      filter(loaded => !!loaded),
      first()
    );
  }
}
