import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { RentalService } from '../../services/rental.service';
import { available_rentalActions } from "./available_rental.actions";

@Injectable()
export class Available_rentalEffects {
  constructor(
    private actions$: Actions,
    private rentalService: RentalService
  ) {}

  loadRentals$ = createEffect(() => this.actions$.pipe(
    ofType(available_rentalActions.loadAvailableRentals),
    mergeMap(() => from(this.rentalService.getAvailableRentals())
      .pipe(
        map(availableRentals => {
          return available_rentalActions.loadAvailableRentalsSuccess({ availableRentals })
        }),
        catchError(error => of(available_rentalActions.loadAvailableRentalsFailure({ error })))
      )
    )
  ));


}
