import { createReducer, on } from '@ngrx/store';
import { available_rentalActions } from "./available_rental.actions";
import { AvailableRental } from "./available_rental.model";

export interface AvailableRentalState {
  availableRentals: AvailableRental[];
  error: any;
  status: 'idle' | 'loading' | 'succeeded' | 'failed' | string;
}

export const initialState: AvailableRentalState = {
  availableRentals: [],
  error: null,
  status: 'idle',
};

export const available_rentalReducer = createReducer(
  initialState,
  on(available_rentalActions.loadAvailableRentals, (state) => {
    return { ...state, status: 'loading' };
  }),
  on(available_rentalActions.loadAvailableRentalsSuccess, (state, action) => {
    return { ...state, availableRentals: action.availableRentals, status: 'succeeded' };
  }),
  on(available_rentalActions.loadAvailableRentalsFailure, (state, action) => {
    return { ...state, error: action.error, status: 'failed' };
  })
);
