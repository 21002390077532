import { createReducer, on } from '@ngrx/store';
import { Event, EventRegistrationStatusEnum, ExtendedEvent } from "./event.model";
import { eventActions } from "./event.actions";

export interface EventState {
  events: (Event & ExtendedEvent)[];
  error: string | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed' | string;
}

export const initialState: EventState = {
  events: [],
  error: null,
  status: 'idle',
};

export const eventReducer = createReducer(
  initialState,
  on(eventActions.loadEvents, (state) => {
    return { ...state, status: 'loading' };
  }),

  on(eventActions.loadEventsSuccess, (state, { events }) => {
    return { ...state, status: 'succeeded', events: events };
  }),
  on(eventActions.loadEventsFailure, (state, { error }) => {
    return { ...state, status: 'failed', error: error };
  }),
  on(eventActions.saveEvent, (state) => {
    return { ...state, status: 'loading' };
  }),
  on(eventActions.saveEventSuccess, (state, { event }) => {
    return { ...state, status: 'succeeded', events: [...state.events, event] };
  }),
  on(eventActions.saveEventFailure, (state, { error }) => {
    return { ...state, status: 'failed', error: error };
  }),
  on(eventActions.deleteEvent, (state) => {
    return { ...state, status: 'loading' };
  }),
  on(eventActions.deleteEventSuccess, (state, { eventId }) => {
    return { ...state, status: 'succeeded', events: state.events.filter(event => event.id !== eventId) };
  }),
  on(eventActions.deleteEventFailure, (state, { error }) => {
    return { ...state, status: 'failed', error: error };
  }),
  on(eventActions.removeFromEvent, (state) => {
    return { ...state, status: 'loading' };
  }),
  on(eventActions.removeFromEventSuccess, (state, { eventId, registration }) => {
    return { ...state, status: 'succeeded',
      events: state.events.map((event) => {
        if (event.id === eventId) {
          return {
            ...event,
            registrations: event.registrations.filter((reg) => reg.id !== registration.id),
          };
        }
        return event;

      })
    };
  }),
  on(eventActions.approveRegistration, (state) => { return { ...state, status: 'loading' }; }),
  on(eventActions.approveRegistrationSuccess, (state, { eventId, registration }) => {
    return { ...state, status: 'succeeded',
      events: state.events.map((event) => {
        if (event.id === eventId) {
          return {
            ...event,
            registrations: event.registrations.map((reg) => {
              if (reg.id === registration.id) {
                return { ...reg, status: EventRegistrationStatusEnum.APPROVED };
              }
              return reg;
            }),
          };
        }
        return event;
      })
    };
  }),
  on(eventActions.approveRegistrationFailure, (state, { error }) => { return { ...state, status: 'failed', error: error }; }),
  on(eventActions.rejectRegistration, (state) => { return { ...state, status: 'loading' }; }),
  on(eventActions.rejectRegistrationSuccess, (state, { eventId, registration }) => {
    return { ...state, status: 'succeeded',
      events: state.events.map((event) => {
        if (event.id === eventId) {
          return {
            ...event,
            registrations: event.registrations.map((reg) => {
              if (reg.id === registration.id) {
                return { ...reg, status: EventRegistrationStatusEnum.REJECTED, rejectReason: registration.rejected_reason};
              }
              return reg;
            }),
          };
        }
        return event;
      })
    };
  }),
  on(eventActions.rejectRegistrationFailure, (state, { error }) => { return { ...state, status: 'failed', error: error }; }),
  on(eventActions.removeFromEventFailure, (state, { error }) => {
    return { ...state, status: 'failed', error: error };
  }),
);
