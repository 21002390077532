import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { of, from } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UserService } from "../../services/user.service";
import { AppState } from "../app-state";
import { loadUser, loadUserFailure, loadUserSuccess, saveUser, saveUserFailure, saveUserSuccess } from "./user.actions";
import { selectUser } from "./user.selectors";
import { ToastController } from "@ionic/angular/standalone";

@Injectable()
export class UserEffects {
    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private userService: UserService,
        private toastController: ToastController
    ) {
    }

    // Run this code when a loadTodos action is dispatched
    loadUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadUser),
            switchMap(() =>
                // Call the getTodos method, convert it to an observable
                from(this.userService.getUser()).pipe(
                    // Take the returned value and return a new success action containing the todos
                    map((user) => loadUserSuccess({ user: user })),
                    // Or... if it errors return a new failure action containing the error
                    catchError((error) => of(loadUserFailure({ error })))
                )
            )
        )
    );

    // Run this code when the addTodo or removeTodo action is dispatched
    saveUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveUser),
            withLatestFrom(this.store.select(selectUser)),
            switchMap(([action, user]) =>
                from(this.userService.saveUser(user)).pipe(
                    map(() => {
                        this.toastController
                            .create({
                                message: 'user saved!',
                                duration: 2000,
                            })
                            .then((toast) => toast.present());
                        return saveUserSuccess({ user: user })
                    }),
                    catchError((error) => {
                        this.toastController
                            .create({
                                message: 'error saving user!',
                                duration: 2000,
                            })
                            .then((toast) => toast.present());
                        return of(saveUserFailure({ error }));
                    })
                )
            )
        )
    );

    // // Run this code when the addTodo or removeTodo action is dispatched
    // saveUser$ = createEffect(
    //   () =>
    //     this.actions$.pipe(
    //       ofType(addTodo, removeTodo),
    //       withLatestFrom(this.store.select(selectAllTodos)),
    //       switchMap(([action, todos]) => from(this.todoService.saveTodos(todos)))
    //     ),
    //   // Most effects dispatch another action, but this one is just a "fire and forget" effect
    //   { dispatch: false }
    // );
}
